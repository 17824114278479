@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: #ffffff;
}

.shimmer-line {
  @apply h-2.5 bg-gray-500 mt-5;
}

.shimmer-title {
  @apply h-6 bg-gray-500 mt-5;
}

.shimmer-line-valign {
  @apply h-2.5 bg-gray-500;
}

.shimmer-circle {
  @apply rounded-full bg-gray-500;
}

@keyframes shimmer-animation {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer-animate {
  animation: shimmer-animation 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

/**
*  Used to override Tailwind styles... sort of.
*  Commented out the unnecessary ones for now since it seems that,
*  at least, the table ones are causing widespread issues.
*  I have to assume the others may as well so I'm limiting the
*  overrides to only the necessities for now.
**/

.override-tailwind {
  font-family: Roboto;
}

/* .override-tailwind p {
  display: block !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
  margin: 1em 0 !important;
}

.override-tailwind blockquote {
  border-left: 4px solid #666 !important;
  padding-left: 10px !important;
  font-style: italic !important;
} */

.override-tailwind ul {
  display: block !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  padding-inline-start: 40px !important;
  list-style-type: disc !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
  counter-reset: list-item !important;
  list-style-image: none !important;
  list-style-position: outside !important;
  margin: 1em 0 !important;
  padding: 0 0 0 30pt !important;
}

.override-tailwind ol {
  display: block !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  padding-inline-start: 40px !important;
  list-style-type: decimal !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
  counter-reset: list-item !important;
  list-style-image: none !important;
  list-style-position: outside !important;
  margin: 1em 0 !important;
  padding: 0 0 0 30pt !important;
}

.override-tailwind b {
  font-weight: bolder !important;
}

.override-tailwind i {
  font-style: italic !important;
}

.override-tailwind h1 {
  font-family: Poppins;
  display: block !important;
  font-weight: bold !important;
  font-size: 2em !important;
  margin-block-start: 0.67em !important;
  margin-block-end: 0.67em !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.override-tailwind h2 {
  font-family: Poppins;
  display: block !important;
  font-weight: bold !important;
  font-size: 1.5em !important;
  margin-block-start: 0.83em !important;
  margin-block-end: 0.83em !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.override-tailwind h3 {
  font-family: Poppins;
  display: block !important;
  font-weight: bold !important;
  font-size: 1.17em !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}
.override-tailwind h4 {
  font-family: Poppins;
  display: block !important;
  font-weight: bold !important;
  font-size: 1em !important;
  margin-block-start: 1.33em !important;
  margin-block-end: 1.33em !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.override-tailwind h5 {
  font-family: Poppins;
  display: block !important;
  font-weight: bold !important;
  font-size: 0.83em !important;
  margin-block-start: 1.67em !important;
  margin-block-end: 1.67em !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}
.override-tailwind h6 {
  font-family: Poppins;
  display: block !important;
  font-weight: bold !important;
  font-size: 0.67em !important;
  margin-block-start: 2.33em !important;
  margin-block-end: 2.33em !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

/* .override-tailwind table {
  display: table !important;
  box-sizing: border-box !important;
  border-spacing: 2px !important;
  border-collapse: separate !important;
  text-indent: initial !important;
  border-color: gray !important;
}

.override-tailwind tr {
  display: table-row !important;
  border-color: inherit !important;
  vertical-align: inherit !important;
}

.override-tailwind td {
  display: table-cell !important;
  padding: 1px !important;
  vertical-align: inherit !important;
} */

.override-tailwind u {
  text-decoration: underline !important;
}

.override-tailwind a {
  color: #0000ee !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.override-tailwind a:active {
  color: #ff0000 !important;
}

.override-tailwind a:visited {
  color: #551a8b !important;
}

/* .override-tailwind pre {
  border-radius: 0.5rem !important;
  padding: 1rem !important;
  font-family: monospace !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  overflow-x: auto !important;
}

.override-tailwind code {
  margin: 0;
  white-space: pre-wrap;
}

.override-tailwind table td,
table th,
table tr {
  background-color: transparent !important;
  border-left: 4px solid #e6e9ec !important;
  border-bottom: 4px solid #e6e9ec !important;
  border-right: 4px solid #e6e9ec !important;
  border-top: 4px solid #e6e9ec !important;
  font-size: inherit !important;
  margin: 0 !important;
  overflow: visible !important;
  padding: 5px !important;
  font-weight: 600 !important;
}

.override-tailwind tr {
  background-color: transparent !important;
  border-left: 4px solid #e6e9ec !important;
  border-bottom: 4px solid #e6e9ec !important;
  font-size: inherit !important;
  margin: 0 !important;
  overflow: visible !important;
  padding: 5px !important;
  font-weight: 600 !important;
}

.override-tailwind .alignleft {
  float: left;
  margin: 0 1em 0 0;
}

.override-tailwind .ytp-cued-thumbnail-overlay-image {
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
}

.override-tailwind .html5-video-player {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  outline: 0;
  font-family: 'YouTube Noto', Roboto, Arial, Helvetica, sans-serif;
  color: #eee;
  text-align: left;
  direction: ltr;
  font-size: 11px;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
  -ms-high-contrast-adjust: none;
} */

/**
*  Used to hide the number arrows on input[type="number"]
**/
.hide-number-arrows input::-webkit-outer-spin-button,
.hide-number-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-number-arrows input[type='number'] {
  -moz-appearance: textfield;
}

@media print {
  .page-break-after {
    page-break-after: always;
  }
}

/* Change cursor to pointer on charts with drill-downs on label clicks */
.drill-down-chart .apexcharts-yaxis-label {
  cursor: pointer;
}

.file-dropzone {
  @apply border border-dashed border-gray-400 rounded-md;
}

a.standard-link {
  @apply text-red-primary;
}

div.NgxEditor__Wrapper {
  @apply rounded-md;
}
